import { NuxtAxiosInstance } from '@nuxtjs/axios'
import fileDownload from 'js-file-download'
import processError from '~/helpers/processError'
import { AnalyticsRepo, CustomerFilter } from '~/types'

export default ($axios: NuxtAxiosInstance, $sentry: any): AnalyticsRepo => ({
  async fetchStaffAnalytics(params?: {
    page: number
    per: number
    branch_id: number
    start_date: string
    end_date: string
    account_status?: string[]
    employment_status?: string[]
  }) {
    try {
      const res = await $axios.get('therapists/analytics/staff', {
        params,
      })
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },

  async fetchCommonAnalytics(params?: {
    branch_ids: number[]
    start_date: string
    end_date: string
  }) {
    try {
      const res = await $axios.get('therapists/analytics/common', {
        params,
      })
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async fetchCustomerCountAnalytics(params?: {
    branch_ids: number[]
    start_date: string
    end_date: string
  }) {
    try {
      const res = await $axios.get('therapists/analytics/customers/count', {
        params,
      })
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },

  async fetchPaymentAnalytics(params?: {
    branch_ids: number[]
    start_date: string
    end_date: string
  }) {
    try {
      const res = await $axios.get('therapists/analytics/payments', {
        params,
      })
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },

  async fetchReservationAnalytics(params?: {
    branch_ids: number[]
    category_ids?: number[]
    tag_ids?: number[]
    staff_ids?: number[]
    start_date: string
    end_date: string
    session: string
  }) {
    try {
      const res = await $axios.get('therapists/analytics/reservations', {
        params,
      })
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async downloadReservationAnalyticsInExcel(params?: {
    branch_ids: number[]
    staff_ids?: number[]
    category_ids?: number[]
    start_date: string
    end_date: string
    session: string
  }) {
    try {
      const res = await $axios.get(
        'therapists/analytics/reservations/export.xlsx',
        {
          params,
          responseType: 'blob',
        }
      )
      if (res.status === 200) {
        return fileDownload(
          res.data,
          `report_${params?.start_date}_${params?.end_date}.xlsx`
        )
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async downloadReservationAnalyticsInPdf(params?: {
    branch_ids: number[]
    category_ids?: number[]
    start_date: string
    end_date: string
    session: string
  }) {
    try {
      const res = await $axios.get(
        'therapists/analytics/reservations/export.pdf',
        {
          params,
          responseType: 'blob',
        }
      )
      if (res.status === 200) {
        return fileDownload(res.data, 'report.pdf')
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async fetchReservationTagAnalytics(params: {
    branch_id: number
    start_date: string
    end_date: string
  }) {
    try {
      const res = await $axios.get(
        `therapists/branches/${params.branch_id}/analytics/reservation_tags`,
        {
          params,
        }
      )
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async fetchCustomerTagAnalytics(params: {
    branch_id: number
    start_date: string
    end_date: string
  }) {
    try {
      const res = await $axios.get(
        `therapists/branches/${params.branch_id}/analytics/customer_tags`,
        {
          params,
        }
      )
      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async exportClinicReport(params: { start_date: string; end_date: string }) {
    try {
      const res = await $axios.post('therapists/analytics/reports/export', {
        report: {
          ...params,
        },
      })
      if (res.status === 201) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async exportCustomerReport(params: {
    branch_option: string
    branch_ids: number[]
    export_attributes?: string[]
    name?: string
    filters?: CustomerFilter[]
    tag_ids?: number[]
  }) {
    try {
      const res = await $axios.post(
        'therapists/analytics/customers_reports/export',
        {
          report: {
            ...params,
          },
        }
      )
      if (res.status === 201) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async exportTicketPacksHistory(params: {}) {
    try {
      const res = await $axios.post(
        'therapists/analytics/tickets_packs/export_history',
        {
          report: {
            filters: params,
          },
        }
      )
      if (res.status === 201) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
  async exportTicketPacksAnnualReport() {
    try {
      const res = await $axios.post(
        'therapists/analytics/tickets_packs/export_annual'
      )
      if (res.status === 201) {
        return res.data
      }
    } catch (error) {
      processError(error, $sentry)
    }
  },
})
