import { render, staticRenderFns } from "./TheFeedbackForm.vue?vue&type=template&id=4dabde31&"
import script from "./TheFeedbackForm.vue?vue&type=script&lang=ts&"
export * from "./TheFeedbackForm.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "~/i18n/feedback.yaml?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fcodebuild%2Foutput%2Fsrc1474891451%2Fsrc%2Fthreease_pro%2Fcomponents%2Fshared%2FTheFeedbackForm.vue&lang=yaml"
if (typeof block0 === 'function') block0(component)
import block1 from "~/i18n/common.yaml?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fcodebuild%2Foutput%2Fsrc1474891451%2Fsrc%2Fthreease_pro%2Fcomponents%2Fshared%2FTheFeedbackForm.vue&lang=yaml"
if (typeof block1 === 'function') block1(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VBtn,VCard,VCardActions,VCardText,VCardTitle,VDialog,VSelect,VSpacer,VTextarea})
