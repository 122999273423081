import { render, staticRenderFns } from "./TheHQSideBar.vue?vue&type=template&id=2b98c7d2&scoped=true&"
import script from "./TheHQSideBar.vue?vue&type=script&lang=ts&"
export * from "./TheHQSideBar.vue?vue&type=script&lang=ts&"
import style0 from "./TheHQSideBar.vue?vue&type=style&index=0&id=2b98c7d2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b98c7d2",
  null
  
)

/* custom blocks */
import block0 from "~/i18n/sidebar.yaml?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fcodebuild%2Foutput%2Fsrc1474891451%2Fsrc%2Fthreease_pro%2Fcomponents%2Fshared%2FSideBar%2FTheHQSideBar.vue&lang=yaml"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
installComponents(component, {VNavigationDrawer})
