import { render, staticRenderFns } from "./NonPermitted.vue?vue&type=template&id=fe15143a&scoped=true&"
import script from "./NonPermitted.vue?vue&type=script&lang=ts&"
export * from "./NonPermitted.vue?vue&type=script&lang=ts&"
import style0 from "./NonPermitted.vue?vue&type=style&index=0&id=fe15143a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fe15143a",
  null
  
)

/* custom blocks */
import block0 from "~/i18n/error.yaml?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fcodebuild%2Foutput%2Fsrc1474891451%2Fsrc%2Fthreease_pro%2Fcomponents%2Fshared%2FBase%2Ferror%2FNonPermitted.vue&lang=yaml"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseButton: require('/codebuild/output/src1474891451/src/threease_pro/components/shared/Base/button/BaseButton.vue').default})


/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VDivider,VRow})
