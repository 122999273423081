import { render, staticRenderFns } from "./ClinicSettingSecondarySideBarList.vue?vue&type=template&id=66e71a04&scoped=true&"
import script from "./ClinicSettingSecondarySideBarList.vue?vue&type=script&lang=ts&"
export * from "./ClinicSettingSecondarySideBarList.vue?vue&type=script&lang=ts&"
import style0 from "./ClinicSettingSecondarySideBarList.vue?vue&type=style&index=0&id=66e71a04&scoped=true&lang=scss&"
import style1 from "./ClinicSettingSecondarySideBarList.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66e71a04",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseButton: require('/codebuild/output/src1474891451/src/threease_pro/components/shared/Base/button/BaseButton.vue').default})


/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VIcon,VListItem,VListItemTitle})
